










































































































































































































































































































































































































@import "../../assets/css/antd.css";
.ant-calendar-disabled-cell .ant-calendar-date {
    position: relative;
    width: auto;
    color: rgba(0, 0, 0, 0.25);
    background: transparent; 
    border: 1px solid transparent;
    border-radius: 0;
    cursor: not-allowed;
}
.load{
	text-align: center;
  	background: rgba(0, 0, 0, 0.05);
  	border-radius: 4px;
  	padding: 30px 50px;
  	padding-top: 200px !important;
  	margin: 20px 0;
}
